export const siteInfo = {
  name: "ChinaSource",
  navColor: "#628819",
  secondary: "#9B4444",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.6,
  sitekey: "bzadactoklab",
  minimum_price: 1000,
  url: "https://chinasource.com.bd",
  cdnUrl: "https://cdn.chinasource.com.bd",
  phone: "01799287987",
  address: "House:31, Road: 5, Sector: 10, Dhaka, Bangladesh",
  email: "info@chinasource.com",
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  by_air: {
    a: 630,
    b: 750,
  },
};
